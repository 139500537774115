/* -----------------------------------------------------------------------------
/* Properties
/* -------------------------------------------------------------------------- */

:root {
  /* base colors - ref: /ui/tailwind.preset.js */
  --bol-shade-2: #f9fafc;
  --bol-shade-8: #edeef0;
  --bol-shade-10: #e6e9ec;
  --bol-shade-20: #cdd2d9;
  --bol-shade-50: #88939e;
  --bol-shade-100: #1a1919;

  /* colors */
  --datagrid-color: var(--bol-shade-100);
  --datagrid-cell-border-color: var(--bol-shade-10);
  --datagrid-cell-bg-color-header: var(--bol-shade-2);
  --datagrid-cell-bg-color-header-hover: var(--bol-shade-8);
  --datagrid-cell-bg-color-overflow-content: var(--bol-shade-2);
  --datagrid-checkbox-border-color: var(--bol-shade-20);
  --datagrid-pager-button-border-color: var(--bol-shade-10);
  --datagrid-pager-button-bg-color-disabled: var(--bol-shade-2);
  --datagrid-pager-button-color-disabled: var(--bol-shade-50);

  /* typography */
  --datagrid-font-weight: 400;
  --datagrid-font-weight-semibold: 600;
  --datagrid-font-size: 14px;
  --datagrid-line-height: 20px;

  /* spacing/sizing */
  --datagrid-border-radius: 6px;
  --datagrid-cell-padding-block: 10px;
  --datagrid-cell-padding-inline: 10px;
  --datagrid-cell-padding-inline-se: 30px;
  --datagrid-pager-gap: 2px;
  --datagrid-pager-button-size: 36px;
}

/* Property overrides for "Small Tables" */
.datagrid-sm {
  /* typography */
  --datagrid-font-size: 13px;
  --datagrid-line-height: 18px;

  /* spacing/sizing */
  --datagrid-cell-padding-block: 7px;
  --datagrid-cell-padding-inline-se: 20px;
}

/* Property overrides for "Large Tables" */
.datagrid-lg {
  /* spacing/sizing */
  --datagrid-cell-padding-block: 17px;
}

/* -----------------------------------------------------------------------------
/* Cells: base
/* -------------------------------------------------------------------------- */

.dx-datagrid td {
  font-weight: var(--datagrid-font-weight);
  font-size: var(--datagrid-font-size) !important;
  line-height: var(--datagrid-line-height) !important;
  padding-block: var(--datagrid-cell-padding-block) !important;
  padding-inline: var(--datagrid-cell-padding-inline) !important;
}

.dx-datagrid td:first-child {
  padding-inline-start: var(--datagrid-cell-padding-inline-se) !important;
}

.dx-datagrid td:last-child:not(.dx-command-adaptive-hidden),
.dx-datagrid td:has(+ td.dx-command-adaptive-hidden) {
  text-overflow: clip;
  padding-inline-end: var(--datagrid-cell-padding-inline-se) !important;
}

.dx-datagrid .dx-datagrid-headers td {
  font-weight: var(--datagrid-font-weight-semibold);
  background-color: var(--datagrid-cell-bg-color-header);
  color: var(--datagrid-color);
}

.dx-datagrid .dx-datagrid-headers td:hover,
.dx-datagrid .dx-datagrid-headers td:focus-visible {
  background-color: var(--datagrid-cell-bg-color-header-hover) !important;
}

/* -----------------------------------------------------------------------------
/* Cells: row selection
/* -------------------------------------------------------------------------- */

.dx-checkbox-icon {
  border-width: 1px;
  border-color: var(--datagrid-checkbox-border-color);
}

.dx-checkbox-icon::after {
  display: none;
}

/* -----------------------------------------------------------------------------
/* Cells: adaptive rows
/* -------------------------------------------------------------------------- */

.dx-datagrid-rowsview .dx-adaptive-detail-row > .dx-master-detail-cell {
  padding-block: 20px !important;
  background-color: var(--datagrid-cell-bg-color-overflow-content) !important;
}

.dx-datagrid-rowsview .dx-adaptive-detail-row .dx-collection {
  gap: 20px 10px;
}

.dx-datagrid-rowsview .dx-adaptive-detail-row .dx-field-item {
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 0 !important;
}

.dx-datagrid-rowsview .dx-adaptive-detail-row .dx-field-item-label {
  font-weight: var(--datagrid-font-weight-semibold);
  font-size: var(--datagrid-font-size);
  padding: 0;
}

.dx-datagrid-rowsview .dx-adaptive-detail-row .dx-field-item-content {
  line-height: inherit;
  min-height: 0;
  padding: 0;
}

/* -----------------------------------------------------------------------------
/* Pagination
/* -------------------------------------------------------------------------- */

.dx-pager {
  padding-block: var(--datagrid-cell-padding-block);
  padding-inline: var(--datagrid-cell-padding-inline-se);
}

.dx-pager .dx-pages {
  float: none;
  display: inline-flex;
  align-items: center;
}

.dx-pager .dx-pages .dx-page-indexes {
  display: inline-flex;
  gap: var(--datagrid-pager-gap);
  height: var(--datagrid-pager-button-size);
}

.dx-pager .dx-pages .dx-page,
.dx-pager .dx-pages .dx-navigate-button,
.dx-pager .dx-pages .dx-separator {
  font-size: var(--datagrid-font-size);
  line-height: var(--datagrid-font-size);
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  border-radius: var(--datagrid-border-radius);
  color: var(--datagrid-color);
}

.dx-pager .dx-pages .dx-page {
  padding-inline: 10px;
}

.dx-pager .dx-pages .dx-selection {
  font-weight: var(--datagrid-font-weight-semibold);
  text-decoration: underline;
  background-color: transparent;
}

.dx-pager .dx-pages .dx-navigate-button {
  width: var(--datagrid-pager-button-size);
  border-width: 1px;
  border-style: solid;
  border-color: var(--datagrid-pager-button-border-color);
}

.dx-pager .dx-pages .dx-navigate-button.dx-button-disable {
  opacity: 1;
  background-color: var(--datagrid-pager-button-bg-color-disabled);
  color: var(--datagrid-pager-button-color-disabled);
}

.dx-pager .dx-pages .dx-navigate-button::before {
  all: revert;
  font-size: 1.25em;
}

.dx-pager .dx-pages .dx-prev-button {
  margin-inline-end: var(--datagrid-pager-gap);
}

.dx-pager .dx-pages .dx-next-button {
  margin-inline-start: var(--datagrid-pager-gap);
}

.dx-pager .dx-pages .dx-prev-button::before {
  content: "\f012";
}

.dx-pager .dx-pages .dx-next-button::before {
  content: "\f010";
}
